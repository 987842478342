<template>
  <div class="head-bar-container pc">
    <div class="menu-show">
      <ul>
        <li v-show="currentPage != 'home'" class="cursor" @click="goto('home')">
          <a>Home</a>
        </li>
        <li v-show="currentPage == 'home'" class="cursor">
          <a>Home</a>
        </li>
        <li v-show="currentPage == 'home'" class="cursor" @click="goto('about')">
          <a>About</a>
        </li>
        <li v-show="currentPage == 'home'" class="cursor" @click="goto('terms')">
          <a>Terms of services</a>
        </li>
        <li v-show="currentPage == 'home'" class="cursor" @click="goto('privacy')">
          <a>Privacy policy</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    currentPage: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      isOpen: false,
    }
  },
  methods: {
    gohome () {
      window.location.href = '/'
    },
    goto (where) {
      console.log(this.$route.path);
      this.isOpen = false;
      if (where == 'home' && this.$route.path == '/') {
        this.scrollToTop()
        return
      }
      this.$router.push({ name: where });
    },
  },
}
</script>
